import type { I18nOptions } from "vue-i18n";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "cs",
  messages: {
    cs: {
      serverError:
        "Ups! Na naší straně se něco pokazilo. Zkuste to prosím později.",
    },
    en: {
      serverError:
        "Oops! Something went wrong on our end. Please try again later.",
    },
  },
  numberFormats: {
    cs: {
      currency: {
        style: "currency",
        currency: "CZK",
        trailingZeroDisplay: "stripIfInteger",
      },
      currencyCZK: {
        style: "currency",
        currency: "CZK",
        trailingZeroDisplay: "stripIfInteger",
      },
      currencyUSD: {
        style: "currency",
        currency: "USD",
        trailingZeroDisplay: "stripIfInteger",
      },
      currencyEUR: {
        style: "currency",
        currency: "EUR",
        trailingZeroDisplay: "stripIfInteger",
      },
      credits: {
        style: "decimal",
      },
    },
    en: {
      currency: {
        style: "currency",
        currency: "EUR",
        trailingZeroDisplay: "stripIfInteger",
      },
      currencyCZK: {
        style: "currency",
        currency: "CZK",
        trailingZeroDisplay: "stripIfInteger",
      },
      currencyUSD: {
        style: "currency",
        currency: "USD",
        trailingZeroDisplay: "stripIfInteger",
      },
      currencyEUR: {
        style: "currency",
        currency: "EUR",
        trailingZeroDisplay: "stripIfInteger",
      },
      credits: {
        style: "decimal",
      },
    },
  },
  datetimeFormats: {
    cs: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      month: {
        year: "numeric",
        month: "numeric",
      },
      medium: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
    },
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      month: {
        year: "numeric",
        month: "numeric",
      },
      medium: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
    },
  } as I18nOptions["datetimeFormats"],
  pluralRules: {
    cs: (choice: number) => {
      if (choice === 0) {
        return 0;
      }

      if (choice === 1) {
        return 1;
      }

      if ([2, 3, 4].includes(choice)) {
        return 2;
      }

      return 3;
    },
  },
}));
